import { http } from '@/api/requests/httpAxios'
import { AxiosPromise } from 'axios'

export default class MoodleAPI {
  public static getCourseResultsByStudent(
    userId: number,
    courseId: number
  ): AxiosPromise {
    const params = new URLSearchParams()
    params.append('userId', userId.toString())
    params.append('courseId', courseId.toString())
    return http.get('/moodle/courses/results/by-student/course', { params })
  }
  public static getCourseResultsByCohort(organizationId: number): AxiosPromise {
    const params = new URLSearchParams()
    params.append('organizationId', organizationId.toString())
    return http.get('/moodle/courses/results/by-cohort/all', { params })
  }
  public static testGetLast5Courses() {
    return http.get('/moodle/test/last5')
  }
  public static testCreateUser(data: any) {
    return http.post('/moodle/test/create-user', data)
  }
  public static testAddMemberCohort(data: any) {
    return http.post('/moodle/test/add-member-cohort', data)
  }
  public static testDeleteMemberFromCohort(data: {
    organizationId: number
    userId: number
  }) {
    const params = new URLSearchParams()
    params.append('organizationId', data.organizationId.toString())
    params.append('userId', data.userId.toString())
    return http.delete('/moodle/test/delete-member-cohort', { params })
  }
  public static testAssignMoodleRoles(data: any) {
    return http.post('/moodle/test/assign-moodle-roles-request', data)
  }
  public static testGetCourseResultsRequest(data: any) {
    const params = new URLSearchParams()
    params.append('userId', data.userId.toString())
    params.append('courseId', data.courseId.toString())
    return http.get('/moodle/test/get-user-course-results', { params })
  }
  public static testGetStudentResultsRequest(data: any) {
    const params = new URLSearchParams()
    params.append('userId', data.userId.toString())
    return http.get('/moodle/test/get-user-courses-results', { params })
  }
  public static testCreateCohort(data: any) {
    return http.post('/moodle/test/create-cohort', data)
  }
  public static testUpdateCohort(data: any) {
    return http.put('/moodle/test/update-cohort', data)
  }
  public static testDeleteCohort(data: any) {
    const params = new URLSearchParams()
    params.append('id', data.id.toString())
    return http.delete('/moodle/test/delete-cohort', { params })
  }
  public static testUpdateUser(data: any) {
    return http.put('/moodle/test/update-user', data)
  }
  public static testGetCourse(data: any) {
    const params = new URLSearchParams()
    params.append('courseId', data.courseId.toString())
    return http.get('/moodle/test/get-course', { params })
  }
  public static testGetCohortResults(data: any) {
    const params = new URLSearchParams()
    params.append('cohortId', data.cohortId.toString())
    return http.get('/moodle/test/get-cohort-results', { params })
  }
}
