


















































import { Vue, Component } from 'vue-property-decorator'
import MoodleAPI from '@/api/requests/moodle'
import { moodleURL } from '@/api/requests/httpAxios'

@Component
export default class DevelopmentMoodlePage extends Vue {
  private moodleURL_ = moodleURL
  private requests: any[] = [
    {
      name: 'ТОП-5 курсов',
      url: '/moodle/test/last5',
      manager: MoodleAPI.testGetLast5Courses,
      response: null
    },
    {
      name: 'Получение курса',
      url: '/moodle/test/get-course',
      manager: MoodleAPI.testGetCourse,
      response: null,
      fields: {
        courseId: 2
      }
    },
    {
      name: 'Создание пользователя',
      url: '/moodle/test/create-user',
      manager: MoodleAPI.testCreateUser,
      response: null,
      fields: {
        password: '123456',
        surname: 'test1000',
        name: 'Никита',
        email: 'test1000@mail.ru'
      }
    },
    {
      name: 'Обновление пользователя',
      url: '/moodle/test/update-user',
      manager: MoodleAPI.testUpdateUser,
      response: null,
      fields: {
        id: 3
      }
    },
    {
      name: 'Добавление пользователя в когорту',
      url: '/moodle/test/add-member-cohort',
      manager: MoodleAPI.testAddMemberCohort,
      response: null,
      fields: {
        organizationId: 1,
        userId: 1
      }
    },
    {
      name: 'Удаление пользователя из когорты',
      url: '/moodle/test/delete-member-cohort',
      manager: MoodleAPI.testDeleteMemberFromCohort,
      response: null,
      fields: {
        organizationId: 1,
        userId: 1
      }
    },
    {
      name: 'Назначение пользователю ролей',
      url: '/moodle/test/assign-moodle-roles-request',
      manager: MoodleAPI.testAssignMoodleRoles,
      response: null,
      description:
        'user: 0\n' +
        'admin: 1\n' +
        'contentmanager: 2\n' +
        'teacher: 3\n' +
        'curator: 4\n' +
        'student: 5',
      fields: {
        role: 'admin',
        userId: 1
      }
    },
    {
      name: 'Результаты студента за курс',
      url: '/moodle/test/get-user-course-results',
      manager: MoodleAPI.testGetCourseResultsRequest,
      response: null,
      fields: {
        courseId: 1,
        userId: 1
      }
    },
    {
      name: 'Результаты студента за курсы',
      url: '/moodle/test/get-user-courses-results',
      manager: MoodleAPI.testGetStudentResultsRequest,
      response: null,
      fields: {
        userId: 1
      }
    },
    {
      name: 'Создание когорты',
      url: '/moodle/test/create-cohort',
      manager: MoodleAPI.testCreateCohort,
      response: null,
      fields: {
        cohortId: 21,
        title: 'Организация 21',
        shortDescription: 'какое-то описание'
      }
    },
    {
      name: 'Обновление когорты по org.id',
      url: '/moodle/test/update-cohort',
      manager: MoodleAPI.testUpdateCohort,
      response: null,
      fields: {
        id: 21,
        title: 'Организация 21',
        shortDescription: 'какое-то описание'
      }
    },
    {
      name: 'Удаление когорты',
      url: '/moodle/test/delete-cohort',
      manager: MoodleAPI.testDeleteCohort,
      response: null,
      fields: {
        id: 21
      },
      description:
        'cohortId == id в ответе запроса /moodle/test/create-cohort, а не параметра cohortId'
    },
    {
      name: 'Результаты когорты',
      url: '/moodle/test/get-cohort-results',
      manager: MoodleAPI.testGetCohortResults,
      response: null,
      fields: {
        cohortId: 2
      }
    }
  ]

  private async sendRequest(request: any) {
    request.response = await request.manager(request.fields)
  }

  private clear(request: any) {
    request.response = null
  }

  private getJSON(request: any) {
    const data = request.response?.data ?? request.response
    return JSON.stringify(data, null, 4)
  }
}
